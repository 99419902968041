import {useState, useEffect} from "react";

const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState("up");
    const [lastScrollY, setLastScrollY] = useState(0);
    const [ticking, setTicking] = useState(false);
    const threshold = 5; // Минимальное значение изменения скролла, чтобы избежать ложных срабатываний

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = Math.max(window.scrollY, 0);

            if (!ticking) {
                window.requestAnimationFrame(() => {
                    if (Math.abs(currentScrollY - lastScrollY) > threshold) {
                        setScrollDirection(currentScrollY > lastScrollY ? "down" : "up");
                        setLastScrollY(currentScrollY);
                    }
                    setTicking(false);
                });
                setTicking(true);
            }
        };

        window.addEventListener("scroll", handleScroll, {passive: true});

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY, ticking]);

    return scrollDirection;
};

export default useScrollDirection;
